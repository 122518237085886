/* eslint-disable max-lines */
import React from 'react';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router';
import { Box, Button, Fade, Stack } from '@mui/material';
import { Add } from '@mui/icons-material';

import { api, CustomMetricTypeResponse, CustomMetricInputTemplateResponse, LocationsResponse } from 'services/api/main';
import { useMutationNotification } from 'services/stores/hooks';

import UnitQuantityCapture from 'components/input/unit-qty-capture';
import DateRangePicker from 'components/input/date-range-picker';
import GenericDropdown from 'components/input/generic-dropdown';
import ConfirmButton from 'components/input/confirm-button';
import Modal from 'components/shared/modal';

import CustomMetricBatchCaptureFormValues, { ValidationSchema } from './form-values';
import defaultDateRange from 'helpers/default-date-range';

const CustomMetricBatchCaptureForm = React.memo(() => {
  const navigate = useNavigate();
  const { module } = useParams<'module'>();
  const { tab } = useParams<'tab'>();
  const isIntensity = tab === 'intensity';
  const closeModal = React.useCallback(() => navigate('../'), [navigate]);

  const [batchLocationId, setBatchLocationId] = React.useState<string>();
  const [batchDateRange, setBatchDateRange] = React.useState<DateRange>(defaultDateRange);
  const [batchRoot, setBatchRoot] = React.useState<string>();

  const locations: DataLoad<Dictionary<LocationsResponse>> = api.useGetApiCommonLocationsQuery();
  const metricTypeRootMap: DataLoad<Dictionary<CustomMetricTypeResponse[]>> = api.useGetApiCommonCustomMetricTypeRootsQuery({ module: module! as ModuleEnum, isIntensity: isIntensity });
  const metricTypes: DataLoad<CustomMetricInputTemplateResponse[]> = api.useGetApiCommonCustomMetricInputTemplatesQuery({ locationId: batchLocationId, module: module! as ModuleEnum, isIntensity: isIntensity, root: batchRoot }, { skip: !Boolean(batchLocationId) || !Boolean(batchRoot) });
  const [putBatch, { isLoading: isSavingBatch }] = useMutationNotification(api.usePutApiCommonCustomMetricBatchMutation, closeModal, 'Custom Metric Batch');
  
  const onBatchDateChange = React.useCallback((formikValues: any) => {
    if(batchDateRange !== formikValues.batchDateRange)
      setBatchDateRange(() => formikValues.batchDateRange);
  }, [setBatchDateRange, batchDateRange]);

  const onRootChange = React.useCallback((value: string) => {
    setBatchRoot(value);
  }, [setBatchRoot]);

  const onSubmit = React.useCallback(
    (values: CustomMetricBatchCaptureFormValues) => {
      putBatch({ body: values.metricTypes.filter(v => v.quantity.value > 0).map(x => ({ ...x, locationId: batchLocationId, dateRange: values.batchDateRange })) });
    }, [batchLocationId, putBatch]
  );

  return (
    <Modal
      key={'custom-metric-batch'}
      title="Custom Metric Batch Capture"
      isOpen
      onClose={closeModal}
      maxWidth='1150px'
    >
      <Formik
        enableReinitialize
        initialValues={{ batchLocationId: batchLocationId!, batchRoot: batchRoot!, batchDateRange: batchDateRange as any, metricTypes: (metricTypes.data?.map(x => ({ metricTypeId: x.metricTypeId, quantity: { unit: x.quantityUnit } }))) as any ?? [] }}
        validationSchema={ValidationSchema}
        onSubmit={onSubmit}
      >
        {
          formik =>
            <Form>

              <Stack direction="column" sx={{ gap: 2 }}>

                <Stack direction="row" sx={{ gap: 2 }}>
                  <GenericDropdown
                    data={locations?.data ? Object.values(locations.data!) : []}
                    selectElementId='location_dropdown' selectElementLabel='Select Location'
                    getKey={i => i.id}
                    onChange={lid => setBatchLocationId(lid?.id!)}
                    getDisplay={i => i.name!}
                    name={'batchLocationId'}
                    isLoading={locations?.isLoading}
                  />

                  <DateRangePicker
                    direction='row'
                    name={'batchDateRange'}
                    onChange={() => onBatchDateChange(formik.values.batchDateRange)}
                  />

                  <GenericDropdown
                    data={metricTypeRootMap?.data ? Object.keys(metricTypeRootMap.data!) : []}
                    selectElementId='metric_root_dropdown' selectElementLabel='Select Root'
                    getKey={i => i}
                    getDisplay={i => i}
                    onChange={onRootChange}
                    name={'batchRoot'}
                    isLoading={metricTypeRootMap?.isLoading}
                  />
                </Stack>

                {
                  formik.values.metricTypes.map((_val, index) => (
                    <Fade in key={index} mountOnEnter unmountOnExit>
                      <Stack
                        direction='row'
                        spacing={{ xs: 1 }}
                        key={index}
                        justifyContent='space-between'
                      >
                        <GenericDropdown
                          sx={{ mb: 1 }}
                          data={(batchRoot && metricTypeRootMap?.data?.[batchRoot]) ? metricTypeRootMap.data[batchRoot] : []}
                          selectElementId='metric_type_dropdown' selectElementLabel='Select Metric Type'
                          getKey={i => i?.id ?? ''} getDisplay={i => i?.name ?? ''}
                          name={`metricTypes.${index}.metricTypeId`}
                          stackSx={{ minWidth: '100px', maxWidth: '25%' }}
                          onChange={(item) => {
                            formik.setFieldValue(`metricTypes.${index}.metricTypeId`, item?.id);
                            formik.setFieldValue(`metricTypes.${index}.quantity.unit`, item?.quantityUnit ?? '');
                          }}
                        />

                        <UnitQuantityCapture
                          path={`metricTypes.${index}`}
                          unitTypeReadOnly
                        />
                      </Stack>
                    </Fade>
                  ))
                }
              </Stack>

              {
                batchLocationId &&
                <Box display='flex' flexDirection='row' justifyContent={'space-between'} sx={{ pt: 1 }}>
                  <Button
                    variant='outlined'
                    size='large'
                    sx={{ minWidth: '275px', maxWidth: '25%' }}
                    onClick={() => formik.setFieldValue('metricTypes', [...formik.values.metricTypes, { locationId: formik.values.batchLocationId, metricTypeId: '', dateRange: formik.values.batchDateRange }])}
                  >
                    <Add />Add Metric Type
                  </Button>
                  <Box>
                    <ConfirmButton
                      title='Cancel batch capture?'
                      buttonText='Cancel'
                      message='Changes will be lost!'
                      color='primary'
                      confirmText='Yes'
                      cancelText='No'
                      onConfirm={closeModal}
                    />
                    <Button sx={{ ml: 1 }} variant='contained' type='submit' disabled={isSavingBatch}>Save Batch</Button>
                  </Box>
                </Box>
              }
            </Form>
        }
      </Formik>
    </Modal>
  );
});

export default CustomMetricBatchCaptureForm;