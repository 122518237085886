import React from 'react';
import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import { DataGrid, GridCellEditStopParams, GridColDef, GridRowHeightParams, GridRowParams, GridSelectionModel, MuiEvent } from '@mui/x-data-grid';
import { CustomToolbar } from 'components/shared/custom-toolbar';
import GridPlaceholder from 'components/shared/grid-placeholder';
import useModulePermissions from 'helpers/use-module-permissions';
import { ModuleEnum } from 'services/api/main';

export interface EconestDataGridProps<T extends { [key: string]: any } = any> {
  title: string;
  columns: GridColDef[];
  rows: GridRowParams<T>[];
  isLoading?: boolean;
  sx?: SxProps<Theme>;
  HandleRowClicked?: (rowData: GridRowParams<T>) => void;
  hideMenu?: boolean;
  hideFooter?: boolean;
  hideHeader?: boolean;
  autoHeight?: boolean;
  addModalUrl?: string;

  onRowEditStop?: (params: GridCellEditStopParams<any>, event: MuiEvent) => void;

  processRowUpdate?: (newVal, oldVal) => any;
  customButtons?: (isReadOnly?: boolean) => React.ReactNode;
  PaginationComponent?: () => JSX.Element;
  handleSelectedIds?: (ids: GridSelectionModel) => void;
  disableSelectIds?: (params: GridRowParams) => boolean;
  isGraphTable?: boolean;
  module: ModuleEnum;
  getRowHeight?: ({ model }: GridRowHeightParams) => any;
}

const EconestDataGrid = <T extends { [key: string]: any } = any>({
  HandleRowClicked,
  title,
  rows,
  columns,
  isLoading,
  sx,
  hideMenu,
  hideFooter = false,
  hideHeader,
  autoHeight,
  addModalUrl,
  onRowEditStop = undefined,
  processRowUpdate = undefined,
  customButtons,
  PaginationComponent,
  handleSelectedIds,
  disableSelectIds,
  isGraphTable,
  module,
  getRowHeight
}: React.PropsWithChildren<EconestDataGridProps<T>>) => {
  const { readOnly } = useModulePermissions(module);
  
  const DataGridToolBar = React.useMemo(() => () => CustomToolbar({ addModalUrl, title, rows, columns, customButtons, isGraphTable, module }), [addModalUrl, title, rows, columns, customButtons, isGraphTable, module]);
  
  const getRowColorClass = React.useCallback((params) => {
    if(!params.row) return 'row-theme--';

    if(params.row.hasAlerts) {
      return 'row-theme--has-alerts';
    }
    
    if(params.row.lastRow) {
      return 'row-theme--last-row';
    }

    return 'row-theme--';
  }, []);

  const NoRows = React.useMemo(() => () => <GridPlaceholder isLoading={isLoading} />, [isLoading]);
  const NoResults = React.useMemo(() => () => <GridPlaceholder isLoading={isLoading} isFiltered />, [isLoading]);

  const dataGridStyle = {
    borderRadius: '0 0 10px 10px',
    '& .row-theme--has-alerts': {
      bgcolor: '#db00041f',
      '&:hover': {
        bgcolor: '#db000433',
      },
    },
    '& .row-theme--last-row': {
      bgcolor: '#c3c4db',
      '&:hover': {
        bgcolor: '#c3c4db',
      },
    }
  } as SxProps;

  return (
    <Stack sx={{ p: 1, height: '100%', ...sx }}>
      {
        !Boolean(hideHeader) ?
          <Box alignContent='center' sx={{ display: 'grid', backgroundColor: 'secondary.main', border: '0px', borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
            <Typography sx={{ textAlign: 'center', fontWeight: 'bold', color: 'primary.main', letterSpacing: 4, fontSize: 28, gridRow: '1', gridColumn: '1 / 11', mt: 1 }}>{title}</Typography>
          </Box> :
          null
      }
      <Box sx={{ ...dataGridStyle, height: '100%' }}>
        <DataGrid
          rows={rows} 
          getRowHeight={getRowHeight}
          columns={columns}
          onRowDoubleClick={readOnly ? undefined : HandleRowClicked}
          getRowClassName={getRowColorClass}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableSelectionOnClick
          onSelectionModelChange={handleSelectedIds ? (ids) => handleSelectedIds(ids) : undefined}
          isRowSelectable={disableSelectIds}
          checkboxSelection={Boolean(handleSelectedIds)}
          hideFooter={hideFooter}
          autoHeight={autoHeight}
          experimentalFeatures={{ newEditingApi: true }}
          processRowUpdate={processRowUpdate}
          disableColumnMenu
          isCellEditable={(params) => params.row.isEditable ?? true }
          onCellEditStop={onRowEditStop ? (params, event) => onRowEditStop(params, event) : undefined }
          components={{
            Toolbar: hideMenu ? null : DataGridToolBar,
            NoRowsOverlay: NoRows,
            NoResultsOverlay: NoResults,
            Pagination: PaginationComponent,
          }}
          sx={dataGridStyle}/>
      </Box>
    </Stack>
  );
};

export default EconestDataGrid;
