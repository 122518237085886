/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { Box, Divider, Fade, List, ListItemIcon, ListItemText, MenuItem, MenuList, Popper, Typography, IconButton, Container, Stack, ListItemButton, useTheme } from '@mui/material';
import { GetMenuItems, MenuItemType, TechMenuItems } from './menu-items';
import { useNavigate } from 'react-router';
import useIsAuthenticated from 'helpers/use-is-authenticated';
import { debounce } from 'lodash';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import LoginItem from 'components/api-authorization/login-item';
import Notifications from './notifications';
import authService from 'services/authorization/AuthorizeService';
import { Logo } from 'icons/icon-components';
import useGetPermissions from 'helpers/use-permissions';
import { api, FeatureFlagSettings } from 'services/api/main';
import { DataLoad } from 'services/api/common-types';

interface AppDrawerProps {
  open?: boolean;
}
const drawerWidth = 480;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  backgroundColor: theme.palette.primary.main,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  display: 'flex',
  flexDirection:'column',
  backgroundColor: theme.palette.primary.main,
  width: '400px',
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 2px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexGrow:1,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  display: 'flex',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

//TODO: Move out into separate file - `app-drawer-item.tsx`
const EcoMenuListItem = React.memo(({ disabled, index, subMenu, modules, ...props }: MenuItemType) => {
  let secondaryMenus: boolean[] = [];
  subMenu?.forEach((m, i) => secondaryMenus.push(false));
  const navigate = useNavigate();
  const [anchorRef, setAnchorRef] = React.useState<HTMLLIElement>();
  const [open, setOpen] = React.useState(false);
  const [secondaryOpen, setSecondaryOpen] = React.useState(secondaryMenus);
  const handleChevronClick = (event: React.MouseEvent<HTMLElement>, index) => {
    event.stopPropagation();
    const newState = secondaryOpen.map((v, i) => {
      if (i === index) {
        return !secondaryOpen[index];
      } else {
        return false;
      }
    });
    setSecondaryOpen(newState);
  };

  const onClose = React.useMemo(() => debounce(() => setOpen(false), 100), []);

  const onOpen = React.useCallback(() => {
    onClose.cancel();
    setOpen(true);
  }, [onClose, setOpen]);

  const OnItemClick = React.useCallback(() => {
    navigate(props.url);
    setOpen(false);
  }, [navigate, props.url]);

  const OnMenuItemClick = React.useCallback((url) => {
    navigate(url);
    setOpen(false);
  }, [navigate]);
  
  return (
    <>
      <ListItemButton
        aria-describedby={`app-menu-${index}`}
        sx={{ cursor: 'pointer', justifyContent: 'center', alignItems: 'center', maxHeight: '50px' }}
        key={props.label}
        disabled={disabled}
        onClick={OnItemClick}
        ref={setAnchorRef as any}
        onMouseEnter={disabled ? () => { } : onOpen}
        onMouseLeave={onClose}
        disableGutters >
        <ListItemIcon color='white' sx={{ justifyContent: 'center', alignItems: 'center' }}>{props.icon}</ListItemIcon>
      </ListItemButton>
      {
        !subMenu || subMenu.length === 0
          ? null
          : (
            <Popper id={`app-menu-${index}`} open={open} anchorEl={anchorRef} placement="right-start" transition sx={{ zIndex: 10000 }}>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={100}>
                  <Box
                    onMouseEnter={onOpen}
                    onMouseLeave={onClose}
                    sx={{ ml: 2, bgcolor: 'background.paper', borderRadius: 4, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}
                  >
                    <MenuList sx={{ overflow: 'hidden' }}>
                      <MenuItem
                        key={props.label}
                        sx={{ backgroundColor: 'white', color: 'primary.main', fontSize: '18px', p: 2, pb: 0, gap: 1 }}
                        onClick={OnItemClick} >
                        <ListItemText>
                          <Typography variant="h6" sx={{ color: 'primary.main', fontWeight: 600, textAlign: 'center' }} gutterBottom component="div">{props.label}</Typography>
                        </ListItemText>
                      </MenuItem>
                      <Divider color='#F2F2F2' sx={{ ml: 2, mr: 2 }} />
                      {subMenu.map(({ label, url, icon, secondaryMenu, id, disabled, hidden }, index) => (
                        <Container key={index}>
                          {
                            !hidden &&
                              <MenuItem
                                key={label}
                                disabled={disabled || (Boolean(id) && modules?.any(m => m === id) !== true)}
                                sx={{ backgroundColor: 'white', color: 'primary.main', fontSize: '18px', p: 2, gap: 1 }}
                                onClick={() => OnMenuItemClick(url)}
                              >
                                <ListItemIcon color='primary.main' sx={{ color: 'primary.main' }}>
                                  {icon}
                                </ListItemIcon>
                                <ListItemText>
                                  <Typography variant='subtitle1'>{label}</Typography>
                                </ListItemText>
                                {secondaryMenu == null
                                  ? '' :
                                  <IconButton
                                    id="icon button"
                                    onClick={(e) => handleChevronClick(e, index)} >
                                    {secondaryOpen[index] ? <ExpandLess /> : <ExpandMore />}
                                  </IconButton>
                                }
                              </MenuItem>
                          }
                          {secondaryOpen[index] && secondaryMenu !== null ?
                            secondaryMenu?.map(({ label, url, icon }) => (
                              <MenuItem
                                key={label}
                                sx={{ backgroundColor: 'white', color: 'primary.light', fontSize: '14px', p: 2, gap: 1, ml: 2 }}
                                onClick={() => OnMenuItemClick(url)}
                              >
                                <ListItemIcon color='primary.main' sx={{ color: 'primary.main' }}>
                                  {icon}
                                </ListItemIcon>
                                <ListItemText>
                                  <Typography>{label}</Typography>
                                </ListItemText>
                              </MenuItem>
                            ))
                            : ''
                          }
                        </Container>
                      ))}
                    </MenuList>
                  </Box>
                </Fade>
              )}
            </Popper>
          )
      }
    </>
  );
});

const EcoAppDrawer = (props: AppDrawerProps) => {
  const appTheme = useTheme();
  const [isAuthenticated] = useIsAuthenticated();
  const { roles, permissions } = useGetPermissions();
  const [userId, setUserId] = React.useState<undefined | string>(undefined);
  const [username, setUsername] = React.useState<string | undefined>(undefined);
  const { data: featureFlags }: DataLoad<FeatureFlagSettings> = api.useGetApiCommonSettingsFeatureFlagsQuery();
  const populateState = React.useCallback(async () => {
    const user = await authService.getUser();
    setUsername(user?.profile && typeof user.profile['firstName'] === 'string' ? user.profile['firstName'] : undefined);
    setUserId(user?.profile && typeof user.profile['sub'] === 'string' ? user.profile['sub'] : undefined);
  }, [setUsername]);

  React.useEffect(() => {
    const subscriptionId = authService.subscribe(() => populateState());
    populateState();
    return () => {
      authService.unsubscribe(subscriptionId);
    };
  });
  
  return (
    <Drawer variant="permanent" elevation={0} open={false} >
      <Stack sx={{ display: 'flex', flexDirection: 'column' }} height='100%'>
        <List sx={!isAuthenticated ? { display: 'none' } : { display: 'flex', flexGrow: 1, flexDirection:'column' }}>
          <Box sx={{ ml: '6px' }}>
            <Logo color='white' />
          </Box>
          <Divider color='#F2F2F2' sx={{ mx: 1, my: 2 }} />
          {GetMenuItems(featureFlags!, appTheme)?.map((item, index) => (<EcoMenuListItem key={item.label} index={index} {...item} disabled={item.disabled || permissions?.any(m => m.module === item.id) !== true} modules={permissions ? permissions.map(p => p.module) : []} />))}
        </List>
        <List sx={{ ...(!isAuthenticated ? { display: 'none' } : { display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }) }}>
          <Divider color='#F2F2F2' sx={{ mx: 1, my: 2 }} />
          {TechMenuItems(appTheme).map((item, index) => (
            <EcoMenuListItem
              key={item.label}
              index={index}
              {...item}
              disabled={item.disabled || roles?.any(r => r === item.requiredRole) !== true}
            />
          ))}
          <Notifications userId={userId ?? ''} />
          <LoginItem isAuthenticated={!!isAuthenticated} username={username ?? ''} />
        </List>
      </Stack>
    </Drawer>
  );
};

export default EcoAppDrawer;