/* eslint-disable max-lines */
import LoaderSuspense from 'components/navigation/loader-suspense';
import React from 'react';
import { Route } from 'react-router';
import CustomMetricRoutes from 'components/shared/custom-metrics/custom-metrics-routes';

const EnergyDashboard = React.lazy(() => import('components/energy/energy-dashboard-wrapper'));
const EnergyForm = React.lazy(() => import('components/energy/energy/form/single/energy-form'));
const BatchCapture = React.lazy(() => import('components/energy/energy/form/batch/energy-batch-capture-form'));
const BatchScope3Capture = React.lazy(() => import('components/energy/scope3/usage-data/form/batch/batch-scope3-capture-form'));
const NetZeroApplicationForm = React.lazy(() => import('components/energy/net-zero/application-form/net-zero-application-form'));
const NetZeroApplicationTable = React.lazy(() => import('components/energy/net-zero/net-zero-application-data-grid'));
const EnergyTable = React.lazy(() => import('components/energy/energy/datagrid/energy-data-grid'));
const Scope3EnergyForm = React.lazy(() => import('components/energy/scope3/usage-data/datagrid/scope3-energy-data-grid'));
const Scope3Capture = React.lazy(() => import('components/energy/scope3/usage-data/form/single/scope3-energy-form'));
const Scope3Entries = React.lazy(() => import('components/energy/scope3/relevance-assessment/datagrid/entries-display-form'));
const Scope3InternalFeedback = React.lazy(() => import('components/energy/scope3/relevance-assessment/form/internal-feedback/internal-feedback-questionnaire'));
const Scope3StakeholderFeedback = React.lazy(() => import('components/energy/scope3/relevance-assessment/form/stakeholder/stakeholder-questionnaire'));
const AuditTrail = React.lazy(() => import('components/audit/audit-trail-grid'));
const AlertModal = React.lazy(() => import('components/shared/alert-modal'));
const CarbonTaxGrid = React.lazy(() => import('components/energy/carbon-tax/datagrid/carbon-tax-grid'));
const CarbonTaxModal = React.lazy(() => import('components/energy/carbon-tax/form/calculation-modal'));
const CarbonTaxDashboard = React.lazy(() => import('components/energy/carbon-tax/carbon-tax-dynamic-dashboard'));
const TargetSetup = React.lazy(() => import('components/setup/targets/target-setup'));
const TargetModal = React.lazy(() => import('components/setup/targets/target-modal'));
const NetZeroProjectDataGrid = React.lazy(() => import('components/energy/net-zero/net-zero-project-data-grid'));
const NetZeroProjectMetricModal = React.lazy(() => import('components/energy/net-zero/indicator-form/net-zero-project-indicator-modal'));
const NetZeroProjectTypeForm = React.lazy(() => import('components/energy/net-zero/type-form/net-zero-project-type-form'));
const EnergyDataManagementWrapper = React.lazy(() => import('components/energy/energy/energy-data-management-wrapper'));
const CalculationLogsList = React.lazy(() => import('components/audit/calculation-log-list'));

const EnergyRoutes = () => {
  return (<>
    {CustomMetricRoutes('Energy')}
    <Route
      path='energy'
      element={<LoaderSuspense outlet module='Energy'><EnergyDashboard /></LoaderSuspense>} />
    
    <Route
      path='energy/:tab'
      element={<LoaderSuspense outlet module='Energy'><EnergyDashboard /></LoaderSuspense>} />
    
    <Route
      path='energy/targets'
      element={<LoaderSuspense outlet module='Energy' ><TargetSetup module='Energy' title='Energy Targets' /></LoaderSuspense>}>
      <Route
        path='modal'
        element={<LoaderSuspense modal module='Energy' ><TargetModal module='Energy' title='Energy Targets' /></LoaderSuspense>} />
      <Route
        path='modal/:targetId'
        element={<LoaderSuspense modal module='Energy' ><TargetModal module='Energy' title='Energy Targets' /></LoaderSuspense>} />
    </Route>
    <Route path='energy/Scope1'>
      <Route
        path='table'
        element={<LoaderSuspense outlet module='Energy'><EnergyTable scope='Scope1' /></LoaderSuspense>} >
        <Route
          path='modal'
          element={<LoaderSuspense modal module='Energy'><EnergyForm scope='Scope1' /></LoaderSuspense>} />
        <Route
          path='modal/batch'
          element={<LoaderSuspense modal module='Energy'><BatchCapture /></LoaderSuspense>} />
        <Route
          path='modal/batch/:ids'
          element={<LoaderSuspense modal module='Energy'><BatchCapture /></LoaderSuspense>} />
        <Route
          path='modal/:scope1EnergyId'
          element={<LoaderSuspense outlet modal module='Energy'><EnergyForm scope='Scope1' /></LoaderSuspense>} >
          <Route
            path='alertMessage/:alertId'
            element={<LoaderSuspense modal module='Energy'><AlertModal /></LoaderSuspense>} />
        </Route>
        <Route
          path='modal/:recordId/audit-trail'
          element={<LoaderSuspense modal module='Energy'><AuditTrail module={'Energy'} type='Direct' /></LoaderSuspense>} />
        <Route
          path='modal/:recordId/calculation-logs'
          element={<LoaderSuspense modal module='Energy'><CalculationLogsList /></LoaderSuspense>} />
      </Route>
    </Route>
    <Route path='energy/Scope2'>
      <Route
        path='table'
        element={<LoaderSuspense outlet module='Energy'><EnergyTable scope='Scope2' /></LoaderSuspense>} >
        <Route
          path='modal'
          element={<LoaderSuspense modal module='Energy'><EnergyForm scope='Scope2' /></LoaderSuspense>} />
        <Route
          path='modal/:scope2EnergyId'
          element={<LoaderSuspense outlet modal module='Energy'><EnergyForm scope='Scope2' /></LoaderSuspense>} >
          <Route
            path='alertMessage/:alertId'
            element={<LoaderSuspense modal module='Energy'><AlertModal /></LoaderSuspense>} />
        </Route>
        <Route
          path='modal/batch/:ids'
          element={<LoaderSuspense modal module='Energy'><BatchCapture scope='Scope2' /></LoaderSuspense>} />
        <Route
          path='modal/:recordId/audit-trail'
          element={<LoaderSuspense modal module='Energy'><AuditTrail module={'Energy'} type='Indirect' /></LoaderSuspense>} />
        <Route
          path='modal/:recordId/calculation-logs'
          element={<LoaderSuspense modal module='Energy'><CalculationLogsList /></LoaderSuspense>} />
      </Route>
    </Route>
    <Route path='energy/Scope3'>
      <Route
        path='table'
        element={<LoaderSuspense outlet module='Energy'><Scope3EnergyForm /></LoaderSuspense>} >
        <Route
          path='modal'
          element={<LoaderSuspense modal module='Energy'><Scope3Capture /></LoaderSuspense>} />
        <Route
          path='modal/:scope3EnergyId'
          element={<LoaderSuspense outlet modal module='Energy'><Scope3Capture /></LoaderSuspense>} >
          <Route
            path='alertMessage/:alertId'
            element={<LoaderSuspense modal module='Energy'><AlertModal /></LoaderSuspense>} />
        </Route>
        <Route
          path='modal/batch'
          element={<LoaderSuspense modal module='Energy'><BatchScope3Capture /></LoaderSuspense>} />
        <Route
          path='modal/batch/:ids'
          element={<LoaderSuspense modal module='Energy'><BatchScope3Capture /></LoaderSuspense>} />
        <Route
          path='modal/:recordId/audit-trail'
          element={<LoaderSuspense modal module='Energy'><AuditTrail module={'Energy'} type='Scope3Energy' /></LoaderSuspense>} />
      </Route>
      <Route
        path='entries'
        element={<LoaderSuspense outlet module='Energy'><Scope3Entries /></LoaderSuspense>} />
      <Route
        path='internal'
        element={<LoaderSuspense outlet module='Energy'><Scope3InternalFeedback /></LoaderSuspense>} />
      <Route
        path='stakeholder'
        element={<LoaderSuspense outlet module='Energy'><Scope3StakeholderFeedback /></LoaderSuspense>} />
    </Route>
    <Route path='energy/net-zero' element={<LoaderSuspense module='Energy'><EnergyDashboard /></LoaderSuspense>} />
    <Route path='energy/net-zero'>
      <Route path='application'>
        <Route
          path='table'
          element={<LoaderSuspense outlet module='Energy'><NetZeroApplicationTable /></LoaderSuspense>} >
          <Route
            path='add-modal'
            element={<LoaderSuspense modal outlet module='Energy'><NetZeroApplicationForm /></LoaderSuspense>}>
            <Route
              path='net-zero-project-type'
              element={<LoaderSuspense modal module='Energy'><NetZeroProjectTypeForm /></LoaderSuspense>} />
          </Route>
          <Route
            path='edit-modal/:netZeroApplicationId'
            element={<LoaderSuspense modal outlet module='Energy'><NetZeroApplicationForm /></LoaderSuspense>}>
            <Route
              path='net-zero-project-type'
              element={<LoaderSuspense modal module='Energy'><NetZeroProjectTypeForm /></LoaderSuspense>} />
          </Route>
          <Route
            path='modal/:recordId/audit-trail'
            element={<LoaderSuspense modal module='Energy'><AuditTrail module={'NetZero'} type='NetZeroProjectApplication' /></LoaderSuspense>} />
        </Route>
      </Route>
      <Route path='project'>
        <Route
          path='table'
          element={<LoaderSuspense outlet module='Energy'><NetZeroProjectDataGrid /></LoaderSuspense>} >
          <Route
            path='metric/:projectId'
            element={<LoaderSuspense modal module='Energy'><NetZeroProjectMetricModal /></LoaderSuspense>} />
          <Route
            path='metric/:projectId/:metricId'
            element={<LoaderSuspense modal module='Energy'><NetZeroProjectMetricModal /></LoaderSuspense>} />
          <Route
            path='add-target/:projectId'
            element={<LoaderSuspense modal module='Energy'><TargetModal module='NetZero' title='Net Zero Project Targets' /></LoaderSuspense>} />
          <Route
            path='modal/:recordId/audit-trail'
            element={<LoaderSuspense modal module='Energy'><AuditTrail module='NetZero' type='NetZeroProject' /></LoaderSuspense>} />
        </Route>
      </Route>
    </Route>
    <Route path='energy/carbon-tax' element={<LoaderSuspense module='Energy'><EnergyDashboard /></LoaderSuspense>} />
    <Route path='energy/carbon-tax'>
      <Route
        path='table'
        element={<LoaderSuspense outlet module='Energy'><CarbonTaxGrid /></LoaderSuspense>} >
        <Route
          path='modal'
          element={<LoaderSuspense outlet modal module='Energy'><CarbonTaxModal /></LoaderSuspense>} />
      </Route>
      <Route
        path='graphs'
        element={<LoaderSuspense module='Energy'><CarbonTaxDashboard /></LoaderSuspense>} />
    </Route>
    <Route path='energy/data-management'>
      <Route
        path='table'
        element={<LoaderSuspense outlet module='Energy'><EnergyDataManagementWrapper /></LoaderSuspense>} >
        <Route
          path='modal'
          element={<LoaderSuspense outlet modal module='Energy'><EnergyForm scope='Scope1' /></LoaderSuspense>} />
      </Route>
    </Route>
  </>);
};

export default EnergyRoutes;
