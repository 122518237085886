import React from 'react';
import { ApplicationPaths, QueryParameterNames } from 'services/authorization/ApiAuthorizationConstants';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Person, ExitToApp } from '@mui/icons-material';
import { ClickAwayListener, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import MenuIcons from 'icons/menu-icons';

const LoginItem = ({
  isAuthenticated,
  username,
}: {
  isAuthenticated: boolean;
  username?: string;
}) => {
  const { themeName } = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <ClickAwayListener onClickAway={handleMenuClose}>
      <>
        <Box sx={{ flexDirection: 'row-reverse' }}>
          <Button
            style={{ fontSize: '40px', textAlign: 'center', alignContent: 'center' }}
            sx={{
              ml: '-4px',
              backgroundColor: 'primary.main', color: 'white', '&:hover': {
                color: 'primary.main'
              }
            }}
            href={isAuthenticated ? ApplicationPaths.Profile : ApplicationPaths.Login}
            onMouseEnter={handleMenuOpen}
          >
            {themeName === 'Econest' ? <MenuIcons.Econest.Profile isAuthenticated={isAuthenticated} /> : <MenuIcons.Catalyst.Profile isAuthenticated={isAuthenticated} />}
          </Button>
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          MenuListProps={{ 'aria-labelledby': 'basic-button' }}
          onMouseLeave={handleMenuClose}
          sx={{ marginLeft: 7 }}
        >
          <Typography sx={{ color: 'primary.main', fontWeight: 'bold', mt: 1, justifySelf: 'center', ml: '10%' }} gutterBottom>Logged in as &quot;{username}&quot;</Typography>
          {
            isAuthenticated &&
            <MenuItem style={{ backgroundColor: 'white' }} >
              <Button
                variant="text"
                startIcon={<Person style={{ color: 'primary.main' }} />}
                style={{ color: 'primary.main', fontSize: '18px' }}
                href={ApplicationPaths.IdentityManagePath} >
                Manage profile
              </Button>
            </MenuItem>
          }
          {
            isAuthenticated &&
            <MenuItem style={{ backgroundColor: 'white' }} >
              <Button
                variant="text"
                startIcon={<ExitToApp style={{ color: 'primary.main' }} />}
                style={{ color: 'primary.main', fontSize: '18px' }}
                href={`${ApplicationPaths.LogOut}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(window.location.href)}`} >
                Log Out
              </Button>
            </MenuItem>
          }
        </Menu>
      </>
    </ClickAwayListener>
  );
};

export default LoginItem;
