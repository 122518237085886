import React from 'react';
import { Stack, Tooltip, Typography } from '@mui/material';
import { IQuantity } from 'services/api/main';
import { DisplayAs } from '../formatters/data-grid-formatter';
import QuantityUnitDetail from './quantity-unit-detail';

interface QuantityDisplayTooltipProps {
  quantity: IQuantity
  secondaryQuantity?: IQuantity
  displayAs?: DisplayAs;
  name?: string;
}

export default function QuantityDisplayTooltip ({ quantity, secondaryQuantity, displayAs = 'both', name = 'quantity' }: QuantityDisplayTooltipProps) {

  return (
    <Tooltip title={
      
      Boolean(quantity)
        ? <>
          <QuantityUnitDetail quantity={quantity} showUnitDetail displayAs={displayAs} name={name} />
          {Boolean(secondaryQuantity) && <QuantityUnitDetail quantity={secondaryQuantity!} showUnitDetail name={name} />}
        </>
        : 'N/A'
    }
    placement='right-start'>
      <Stack direction='row' spacing={1}> {Boolean(secondaryQuantity) ?
        <Typography textAlign='center' sx={{ textDecoration: 'underline' }} minWidth={100} >Hybrid Unit</Typography> :
        !Boolean(quantity) ? 'N/A' : <QuantityUnitDetail quantity={quantity} rounding={2} displayAs={displayAs} name={name} />
      }
      </Stack>
    </Tooltip>
  );
}
