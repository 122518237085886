import LoaderSuspense from 'components/navigation/loader-suspense';
import CustomMetricRoutes from 'components/shared/custom-metrics/custom-metrics-routes';
import React from 'react';
import { Route } from 'react-router';

const WaterDashboardWrapper = React.lazy(() => import('components/water/water-dashboard-wrapper'));
const WaterDataGrid = React.lazy(() => import('components/water/water-data-grid'));
const WaterMeterCalibrationModal = React.lazy(() => import('components/water/calibration/water-meter-calibration-modal'));
const WaterClosingReadingModal = React.lazy(() => import('components/water/reading/water-closing-reading-modal'));
const TargetModal = React.lazy(() => import('components/setup/targets/target-modal'));
const TargetSetup = React.lazy(() => import('components/setup/targets/target-setup'));
const AuditTrail = React.lazy(() => import('components/audit/audit-trail-grid'));

const WaterRoutes = () => {
  return (<>
    {CustomMetricRoutes('Water')}
    <Route
      path='water'
      element={<LoaderSuspense outlet module='Water'><WaterDashboardWrapper /></LoaderSuspense>} />
    <Route
      path='water/:tab'
      element={<LoaderSuspense outlet module='Water'><WaterDashboardWrapper /></LoaderSuspense>} />
    <Route path='water'>
      <Route
        path='targets'
        element={<LoaderSuspense outlet module='Water'><TargetSetup module='Water' title='Water Targets' /></LoaderSuspense>} >
        <Route
          path='modal'
          element={<LoaderSuspense modal module='Water'><TargetModal module='Water' title='Capture Water Target' /></LoaderSuspense>} />
        <Route
          path='modal/:targetId'
          element={<LoaderSuspense modal module='Water'><TargetModal module='Water' title='Edit Water Target' /></LoaderSuspense>} />
      </Route>
      <Route
        path='table'
        element={<LoaderSuspense outlet module='Water'><WaterDataGrid /></LoaderSuspense>} >
        <Route
          path='water-calibration'
          element={<LoaderSuspense modal module='Water'><WaterMeterCalibrationModal /></LoaderSuspense>} />
        <Route
          path='water-calibration/:waterMeterCalibrationId'
          element={<LoaderSuspense modal module='Water'><WaterMeterCalibrationModal /></LoaderSuspense>} />
        <Route
          path='water-reading/:waterMeterId'
          element={<LoaderSuspense modal module='Water'><WaterClosingReadingModal /></LoaderSuspense>} />
        <Route
          path='water-reading/:waterMeterId/:waterMeterReadingId'
          element={<LoaderSuspense modal module='Water'><WaterClosingReadingModal /></LoaderSuspense>} />
        <Route
          path='modal/:recordId/audit-trail'
          element={<LoaderSuspense modal module='Water'><AuditTrail module={'Water'} type='WaterMeterReading' /></LoaderSuspense>} />
      </Route>
    </Route>
  </>);
};

export default WaterRoutes;
