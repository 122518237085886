/* eslint-disable @typescript-eslint/no-unused-vars */

interface ThemeOption {
  primary: string;
  primaryHighlight: string;

  secondary: string;
  secondaryHighlight: string;

  fontHeaders: string;
  font: string;
  
  headerFontAdjustments?: {
    letterSpacing?: number;
    lineHeight?: number;
  }
  // background
  // backgroundPaper
}

const MontserratFont = 'Montserrat, sans-serif';

const EconestColours = {
  blue: {
    main: '#00055c',
    mainLight: '#070e8a',
    secondary: '#999BBE',
    secondaryDark: '#ED5C14'
  }
};

export const EconestPalette: ThemeOption = {
  primary: EconestColours.blue.main,
  primaryHighlight: EconestColours.blue.mainLight,

  secondary: EconestColours.blue.secondary,
  secondaryHighlight: EconestColours.blue.secondaryDark,

  fontHeaders: MontserratFont,
  font: MontserratFont
};

const DMSerifDisplay = '"DM Serif Display", serif';
const SourceSans3 = '"Source Sans 3", sans-serif';

const CatalystHeaderAdjustments = {
  // letterSpacing: 1.25,
  // lineHeight: 1.1
};

const CatalystColours = {
  red: {
    main: '#ee4046',
    mono1: '#F05338',
    mono2: '#F26E57',
    mono3: '#F69B8C'
  },
  blue: {
    main: '#364759',
    mono1: '#4A5A6A',
    mono2: '#667381',
    mono3: '#979FA9'
  },
  darkGrey: {
    main: '#646464',
    mono1: '#727579',
    mono2: '#898B8E',
    mono3: '#AEB0B1'
  },
  lightGrey: {
    main: '#8b8b8b',
    mono1: '#979797',
    mono2: '#A7A7A7',
    mono3: '#C3C3C3'
  }
};

export const CatalystBluePalette: ThemeOption = {
  primary: CatalystColours.blue.main,
  primaryHighlight: CatalystColours.blue.mono1,

  secondary: CatalystColours.blue.mono3,
  secondaryHighlight: CatalystColours.red.mono3,

  fontHeaders: SourceSans3, // DMSerifDisplay,
  font: SourceSans3,

  headerFontAdjustments: CatalystHeaderAdjustments
};

export const CatalystRedPalette: ThemeOption = {
  primary: CatalystColours.red.main,
  primaryHighlight: CatalystColours.red.mono1,

  secondary: CatalystColours.red.mono3,
  secondaryHighlight: CatalystColours.blue.mono3,

  fontHeaders: SourceSans3, // DMSerifDisplay,
  font: SourceSans3,

  headerFontAdjustments: CatalystHeaderAdjustments
};

const CatalystRedBlueMixPalette: ThemeOption = {
  primary: CatalystColours.red.main,
  primaryHighlight: CatalystColours.red.mono1,

  secondary: CatalystColours.blue.mono3,
  secondaryHighlight: CatalystColours.red.mono3,

  fontHeaders: SourceSans3, // DMSerifDisplay,
  font: SourceSans3,

  headerFontAdjustments: CatalystHeaderAdjustments
};
