import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, IconButton, Typography, Menu, MenuItem, ListItemText, ListItem, useTheme } from '@mui/material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { filter , truncate } from 'lodash';
import { formatDateString } from '../display/formatters/date-formats';
import { api, UserNotificationResponse, UserNotificationRequest, NotificationTypeEnum } from 'services/api/main';
import { DataLoad } from 'services/api/common-types';
import MenuIcons from 'icons/menu-icons';

interface NotificationsProps {
  userId: string | undefined;
}

const getUrl = (entityType: string, entityId: string, type: NotificationTypeEnum) => {

  const modalSlug = entityId ? `table/modal/${entityId}` : 'table';

  if(type === 'Alert')
    return `alertMessage/${entityId}`;

  switch(entityType) {
    case 'FundingApplication':
      return `communities/csi/${modalSlug}`;
    case 'Energy':
      return `energy/Scope1/${modalSlug}`;
    case 'PersonnelIncident':
      return `health/incidents/${modalSlug}`;
    case 'SafetyIncident':
      return `safety/incidents/${modalSlug}`;
    default:
      return '';
  }
};

const Notifications = (props: NotificationsProps) => {
  const { themeName } = useTheme();

  const { userId } = props;

  const [notifications, setNotifications] = React.useState<UserNotificationResponse[] | undefined>(undefined);
  const notificationsCall: DataLoad<UserNotificationResponse[]> = api.useGetApiNotificationsQuery();
  
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [putNotificationRead] = api.usePutApiNotificationsMutation();
  const openAnchor = Boolean(anchorEl);

  const unreadNotifications = React.useMemo(() => { return notifications ? filter(notifications, { 'isRead': false }) : '';}, [notifications]);

  const onShowNotifications = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget), 
    [setAnchorEl]);

  const handleCloseMenu = React.useCallback(() => setAnchorEl(null), [setAnchorEl]);

  const handleItemClose = React.useCallback((value) => {
    const putValue = {
      ...value,
      isRead: true,
    } as UserNotificationRequest;
    putNotificationRead({ userNotificationRequest: putValue });
    handleCloseMenu();
    navigate(getUrl(value?.notificationEntityType, value?.notificationEntityId, value?.notificationType));
  },
  [putNotificationRead, handleCloseMenu, navigate]
  );

  React.useEffect(() => {
    if (notificationsCall.data) {
      setNotifications(notificationsCall.data);
    }
  }, [userId, notificationsCall]);

  return (
    <ListItem>
      <IconButton sx={{ ml:'-6px' }} size="small" color="inherit" onClick={onShowNotifications}>
        <Badge badgeContent={unreadNotifications.length} sx={{ color: 'white' }}>
          {themeName === 'Econest' ? <MenuIcons.Econest.Alert /> : <MenuIcons.Catalyst.Alert />}
        </Badge>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openAnchor}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            maxHeight: '30vh',
            maxWidth: '30vw',
            marginLeft:60
          }
        }}
      >
        {notificationsCall.data?.map((value) => {
          const displayText = truncate(value?.notificationText, {
            'length': 35
          });
          return (
            <MenuItem onClick={() => handleItemClose(value)} key={value.id}>
              {value?.isRead ?
                <>
                  <ListItemText sx={{ opacity: '0.5', textOverflow: 'ellipsis' }}>{displayText}</ListItemText>
                  <DoneAllIcon sx={{ color: 'primary.light', opacity: '0.5', ml: 1 }} />
                  <Typography variant="body2" sx={{ opacity: '0.5', ml: 1, textOverflow: 'ellipsis' }} noWrap>{formatDateString(value?.notificationCaptureTime)}</Typography>
                </>
                :
                <>
                  <ListItemText sx={{ textOverflow: 'ellipsis' }}>{displayText}</ListItemText>
                  <Typography variant="body2" sx={{ ml: 1 }} noWrap>{formatDateString(value?.notificationCaptureTime)}</Typography>
                </>
              }
            </MenuItem>
          );
        })}
      </Menu>
    </ListItem>
  );
};

export default Notifications;