import React from 'react';
import { Box, Container, FormHelperText, Stack, StackProps } from '@mui/material';
import { DateRange } from 'services/api/main/main-api.g';
import DatePicker from './date-picker';
import { DateTime } from 'luxon';

interface DateRangePickerProps extends Omit<StackProps, 'onChange'> {
  value?: DateRange;
  errors?: any;
  onChange?: ((value: DateTime | null, keyboardInputValue?: string | undefined) => void) | undefined;
  name?: string;
  elementWidth?: number | string;
  elementSx?: any;
}

const defaultName = 'dateRange';

const DateRangePicker = (props: DateRangePickerProps) => {
  const { value, onChange, name, elementWidth, elementSx, ...stackProps } = props;
  const finalName = name || defaultName;
  const startName = !name ? 'startDate' : `${finalName}.startDate`;
  const endName = !name ? 'endDate' : `${finalName}.endDate`;

  return (
    <Container disableGutters>
      <Stack spacing={1} {...stackProps}>
        <Box>
          <DatePicker
            label="Start Date"
            name={startName}
            InputProps={{ sx: { ...elementSx, width: elementWidth ?? '100%' } }}
            disableMaskedInput
            onChange={onChange}
          />
        </Box>
        <Box>
          <DatePicker
            label="End Date"
            name={endName}
            InputProps={{ sx: { ...elementSx, width: elementWidth ?? '100%' } }}
            disableMaskedInput
            onChange={onChange}
          />
        </Box>
      </Stack>
      { typeof props.errors === 'string' && <FormHelperText sx={{ ml: 1, mt: 0, mb: 1 }} error>{props.errors}</FormHelperText> }
    </Container>
  );
};
export default DateRangePicker;
