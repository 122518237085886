import React from 'react';
import { DatePicker as MuiDatePicker, DatePickerProps as MuiDatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon as DateAdapter } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { useField } from 'formik';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { DateDto } from 'services/api/main';

type DatePickerValue = DateDto | undefined | null;

interface DatePickerProps extends Omit<MuiDatePickerProps<DateTime, DateTime>, 'onChange' | 'value' | 'renderInput'> {
  name: string;
  defaultValue?: DateTime | undefined | null;
  transformValue?: (value: DateTime) => any;
  untransformValue?: (value: any, defaultValue: DateTime) => DateTime;
  onChange?: MuiDatePickerProps<DateTime, DateTime>['onChange'];
  value?: MuiDatePickerProps<DateTime, DateTime>['value'];
  renderInput?: MuiDatePickerProps<DateTime, DateTime>['renderInput'];
}

const defaultTransform = (value: DateTime) => ({ year: value.year, month: value.month, day: value.day });
const defaultUntransformValue = (value: any, defaultValue: DateTime) => value ? DateTime.fromObject(value) : defaultValue;

const DefaultRenderDatePickerInput = (props: TextFieldProps) => <TextField type='date' {...props} />;

const DatePicker = React.memo(({
  name,
  defaultValue = DateTime.now(),
  onChange,
  transformValue = defaultTransform,
  untransformValue = defaultUntransformValue,
  ...rest }: DatePickerProps) => {
  const [field, metadata, fieldSets] = useField<DatePickerValue>(name);

  const defaultOnChange = React.useCallback((value: DateTime) => {
    if(!value?.isValid) return;
    
    fieldSets.setValue(transformValue(value));
    onChange?.(value);
  }, [fieldSets, transformValue, onChange]);

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <MuiDatePicker
        onChange={defaultOnChange}
        renderInput={DefaultRenderDatePickerInput}
        value={untransformValue(field.value, defaultValue!)}
        {...rest}
        disableMaskedInput
      />
      {
        (typeof metadata.error === 'string' && metadata.touched) ?
          <FormHelperText error>{metadata.error}</FormHelperText> :
          null
      }
    </LocalizationProvider>
  );
});

export default DatePicker;
