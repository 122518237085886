/* eslint-disable max-lines */
import { createTheme } from '@mui/material/styles';
import { CatalystBluePalette, CatalystRedPalette, EconestPalette } from 'constants/theme-selection';
import { ColourPaletteEnum } from 'services/api/main';

const buildTheme = (flag?: ColourPaletteEnum) => {
  let themePalette;
  switch (flag) {
    case 'Econest':
      themePalette = EconestPalette;
      break;
    case 'CatalystRed':
      themePalette = CatalystRedPalette;
      break;
    case 'CatalystBlue':
    default:
      themePalette = CatalystBluePalette;
      break;
  }

  const headerTypography = {
    fontFamily: themePalette.fontHeaders,
    // ...(themePalette?.headerFontAdjustments ?? {})
  };

  return createTheme({
    themeName: flag ?? 'CatalystBlue',
    palette: {
      primary: {
        main: themePalette.primary,
        light: themePalette.primaryHighlight
      },
      secondary: {
        main: themePalette.secondary,
        dark: themePalette.secondaryHighlight
      },
      error: {
        main: '#ed5c14'
      },
      warning: {
        main: '#d90004c2'
      },
      info: {
        main: '#979FA9',
        light: '#e5e6ee'
      }
    },
  
    typography: {
      fontFamily: themePalette.font,
      'fontSize': 14,
      'fontWeightLight': 300,
      'fontWeightRegular': 400,
      'fontWeightMedium': 500,
      button: {
        textTransform: 'none'
      },
      h1: headerTypography,
      h2: headerTypography,
      h3: headerTypography,
      h4: headerTypography,
      h5: headerTypography,
      h6: headerTypography,
      subtitle1: headerTypography,
      subtitle2: headerTypography,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            background: themePalette.primary,
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: '#979FA9'
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            'fontWeight': '700 !important',
          },
          '& .MuiDataGrid-cell': {
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: 'yellow',
          },
          '& .inLine': {
            'fontSize': '14px !important',
          },
        },
      },
  
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontFamily: themePalette.font,
            'fontWeight': 'MEDIUM',
            'fontSize': 'LARGE',
            paddingRight: '10px'
          },
  
        }
      },
  
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: '#5F5F5F70',
            fontFamily: themePalette.font,
            'fontWeight': 'LARGE',
            'fontSize': '16px',
          },
        }
      },
  
      MuiButton: {
        styleOverrides: { root: { fontSize: '1rem' } }
      },
      MuiTablePagination: {
        styleOverrides: {
          displayedRows: { marginTop: 18 },
          selectLabel: { marginTop: 18 },
        }
      },
  
      MuiPaper: {
        styleOverrides: {
          root: {
            '&::-webkit-scrollbar': { width: '0.3em', backgroundColor: 'transparent', borderRadius: '10px', marginTop: '2px' },
            '&::-webkit-scrollbar-track': { boxShadow: 'inset 0 0 0px rgba(0,0,0,0.00)', webkitBoxShadow: 'inset 0 0 0px rgba(0,0,0,0.00)' },
            '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(255,255,255,0.2)', outline: '1px solid rgba(255,255,255,0.3)', borderRadius: '10px', marginTop: '2px' },
          }
        }
      },
  
      MuiGrid: {
        styleOverrides: {
          root: {
            '&::-webkit-scrollbar': { width: '0.8em', backgroundColor: 'transparent', borderRadius: '10px', marginTop: '2px' },
            '&::-webkit-scrollbar-track': { boxShadow: 'inset 0 0 0px rgba(0,0,0,0.00)', webkitBoxShadow: 'inset 0 0 0px rgba(0,0,0,0.00)' },
            '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(255,255,255,0.2)', outline: '1px solid rgba(255,255,255,0.3)', borderRadius: '10px', marginTop: '2px' },
          }
        }
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: themePalette.primary + '1a'
          }
  
        }
      },
  
      MuiTab: {
        styleOverrides: {
          root: {
            opacity: 1,
            overflow: 'initial',
            color: '#ffffff',
            background: '#979FA9', //rbga(0,0,0,0)
            transition: '0.2s',
            borderRadius: 4,
            '&:before': {
              color: '#ffffff',
              content: '" "',
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              transformOrigin: '100%',
              boxShadow: '3px 3px 8px 0 rgba(0,0,0,0.38)',
              borderRadius: 4,
              paddingBottom: 1
            },
            '&:after': {
              pointerEvents: 'none',
              transition: '0.2s',
              content: '" "',
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              transform: 'translateX(100%)',
              display: 'block',
              width: 8,
              zIndex: 2,
              backgroundColor: '#00055',
              background: 'linear-gradient(to top right, rgba(0,0,0,0.1), rgba(0,0,0,0.1) 45%, transparent, transparent 64%)',
            },
  
            '&.Mui-selected': {
              color: themePalette.primary,
              zIndex: 0,
              transition: '1.0s',
              backgroundColor: 'transparent',
              '&:before': {
                color: themePalette.primary,
                boxShadow: '3px 3px 8px 0 rgba(0,0,0,0.38)',
                backgroundColor: '#transparent',
              },
              '&:after': {
                color: themePalette.primary,
                backgroundColor: 'transparent',
              },
            }
          },
  
        }
  
      },
    },
  });
};

export default buildTheme;