/* eslint-disable max-lines */
import map from 'lodash/map';
import { api } from './main-api.g';

/* 
Tags to be implemented using enhanceEndpoints();
SEE: https://github.com/reduxjs/redux-toolkit/issues/1855

EXAMPLE:
api.enhanceEndpoints({
  endpoints: {
    getABC: {
      providesTags: []
    },
    postABC: {
      invalidatesTags: []
    }
  }
})
*/

export default api.enhanceEndpoints({
  endpoints: {
    //#region Net Zero
    // Applications
    getApiNetZeroApplication: {
      providesTags: ['NetZeroApplication']
    },
    getApiNetZeroApplicationById: {
      providesTags: (_result, _error, arg) => [{ type: 'SignOff', id: arg.id }]
    },
    putApiNetZeroApplication: {
      invalidatesTags: ['NetZeroApplication']
    },
    postApiNetZeroApplicationByNetZeroProjectApplicationIdSignOff: {
      invalidatesTags: (_result, _error, arg) => ['NetZeroApplication', 'SignOff']
    },
    deleteApiNetZeroApplicationById: {
      invalidatesTags: ['NetZeroApplication']
    },

    // Projects
    getApiNetZeroProject: {
      providesTags: ['NetZeroProject']
    },
    postApiNetZeroProjectByNetZeroProjectIdSignOff: {
      invalidatesTags: ['NetZeroProject']
    },
    deleteApiNetZeroProjectById: {
      invalidatesTags: ['NetZeroProject']
    },

    // Metrics
    getApiNetZeroProjectByProjectIdIndicator: {
      providesTags: ['NetZeroProjectIndicator']
    },
    getApiNetZeroProjectByProjectIdIndicatorAndIndicatorId: {
      providesTags: (_result, _error_, arg) => [{ type: 'SignOff', id: arg.indicatorId + arg.projectId }]
    },
    putApiNetZeroProjectByProjectIdIndicator: {
      invalidatesTags: ['NetZeroProjectIndicator']
    },
    postApiNetZeroProjectIndicatorByIndicatorIdSignOff: {
      invalidatesTags: ['NetZeroProjectIndicator', 'SignOff']
    },

    // Project Types
    getApiNetZeroProjectTypeAll: {
      providesTags: ['NetZeroProjectType']
    },
    putApiNetZeroProjectType: {
      invalidatesTags: ['NetZeroProjectType']
    },
    //#endregion Net Zero
    //#region General Energy
    getApiEnergyInputTemplates: {
      providesTags: ['Energy']
    },
    getApiEnergyById: {
      providesTags: (_result, _error_, arg) => [{ type: 'SignOff', id: arg.id }]
    },
    putApiEnergy: {
      invalidatesTags: ['Energy']
    },
    postApiEnergyByIdSignOff: {
      invalidatesTags: ['Energy', 'SignOff']
    },
    deleteApiEnergyById: {
      invalidatesTags: ['Energy']
    },
    getApiEnergyEmissions: {
      providesTags: ['Energy']
    },
    getApiEnergyEmissionsPaginated: {
      providesTags: ['Energy']
    },
    putApiEnergyBatch: {
      invalidatesTags: ['Energy']
    },
    //#endregion General Energy
    //#region Indirect Energy
    postApiEnergyIndirectFromTemplateByFileName: {
      invalidatesTags: [{ type: 'Energy', id: 'Scope2' }]
    },
    //#endregion Indirect Energy
    //#region Scope3 Energy
    getApiEnergyScope3InputTemplates: {
      providesTags: (_result, _error, arg) => [{ type: 'Scope3Energy', id: arg.locationId }]
    },
    getApiEnergyScope3: {
      providesTags: (_result, _error, arg) => [{ type: 'SignOff', id: arg.scope3EnergyId }]
    },
    getApiEnergyScope3Emissions: {
      providesTags: ['Scope3Energy']
    },
    getApiEnergyScope3Paginated: {
      providesTags: (_result, _error) => [{ type: 'Scope3Energy' }]
    },
    putApiEnergyScope3: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'Scope3Energy', id: arg.id }, 'Scope3Energy']
    },
    postApiEnergyScope3ByScope3EnergyIdSignOff: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'SignOff', id: arg.scope3EnergyId }, 'Scope3Energy']
    },
    deleteApiEnergyScope3ByScope3EnergyId: {
      invalidatesTags: (_result, _error) => [{ type: 'Scope3Energy' }]
    },
    putApiEnergyScope3Batch: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'Scope3Energy' }]
    },
  
    //#endregion Scope3 Energy
    //#region Stakeholder Feedback
    postApiStakeholderFeedback: {
      invalidatesTags: ['StakeholderFeedback']
    }, 
    getApiStakeholderFeedback: {
      providesTags: ['StakeholderFeedback']
    },
    getApiStakeholderFeedbackById: {
      providesTags: ['StakeholderFeedback']
    },
    //#endregion Stakeholder Feedback
    //#region Feedback Categories
    getApiFeedbackCategoryStakeholderSolicitation: {
      providesTags: ['StakeholderFeedbackSolicitation']
    },
    //#endregion Feedback Categories
    //#region Locations
    getApiCommonLocations: {
      providesTags: ['Locations']
    },
    postApiCommonLocations: {
      invalidatesTags: (_result, _error, arg) => Boolean(arg.locationsRequest.id) ? [{ type: 'Locations', id: arg.locationsRequest.id! }, 'Locations'] : ['Locations']
    },
    getApiCommonLocationsLockedPeriods: {
      providesTags: ['Locations']
    },
    getApiCommonLocationsByLocationId: {
      providesTags: (_result, _error, arg) => [{ type: 'Locations', id: arg.locationId }]
    },
    //#endregion Locations
    //#region Locked Periods
    deleteApiCommonLockedPeriodsByLockPeriodId: {
      invalidatesTags: ['Locations']
    },
    putApiCommonLockedPeriods: {
      invalidatesTags: (_result, _error, arg) => ['Locations']
    },
    //#endregion Locked Periods
    //#region Units
    getApiCommonUnitsByUnitType: {
      providesTags: (_result, _error, arg) => [{ type: 'Unit', id: arg.unitType }]
    },
    patchApiCommonUnitsByUnitType: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'Unit', id: arg.unitType }]
    },
    //#endregion Units
    //#region Disclosure
    getApiDisclosuresChildren: {
      providesTags: (result, _error, arg) => {
        var newTags: any[] = [];

        if(!arg.parentId) newTags.push('Disclosures');
        
        newTags.concat(result?.map(r => ({ type: 'Disclosures', id: r.id })));
        
        return newTags;
      }
    },
    getApiDisclosuresByDisclosureId: {
      providesTags: (result, _error, _arg) => result?.map(r => ({ type: 'Disclosures', id: r.id })) ?? []
    },
    putApiDisclosuresMapping: {
      invalidatesTags: ['Disclosures']
    },
    getApiDisclosuresByStandardIdAndModuleId: {
      providesTags: ['Disclosures']
    },
    //#endregion Disclosure
    //#region DisclosureAnswer
    getApiDisclosuresAnswers: {
      providesTags: (_result, _error, arg) => [{ type: 'DisclosureAnswer', id: `${arg.disclosureId}@${arg.businessUnitId}/${arg.indexingValue}` }]
    },
    patchApiDisclosuresAnswers: {
      invalidatesTags: (_result, _error, arg) => 
        map(arg.patchDisclosureAnswersDto.answers, (_answerValue, disclosureIdAndIndexingValue) => {
          const [disclosureId, indexingValue] = disclosureIdAndIndexingValue.split('/');
          return ({ type: 'DisclosureAnswer', id: `${disclosureId}@${arg.patchDisclosureAnswersDto.businessUnitId}/${indexingValue}` });
        })
    },
    //#endregion DisclosureAnswer    
    //#region FundingApplications
    getApiCommunitiesCsi: {
      providesTags: ['FundingApplication']
    },
    getApiCommunitiesCsiById: {
      providesTags: (_result, _error, arg) => [{ type: 'SignOff', id: arg.id }]
    },
    getApiCommunitiesCsiByByGrouping: {
      providesTags: ['FundingApplication']
    },
    postApiCommunitiesCsiByFundingApplicationIdSignOff: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'SignOff' }, 'FundingApplication', 'Project']
    },
    putApiCommunitiesCsi: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'FundingApplication', id: arg.id }, 'FundingApplication']
    },
    
    deleteApiCommunitiesCsiByFundingApplicationId: {
      invalidatesTags: ['FundingApplication']
    },
    //#endregion FundingApplications
    //#region HealthAndSafety
    getApiHealthAndSafetyIncident:{
      providesTags: [ 'HealthAndSafetyIncident' ]
    },
    getApiHealthAndSafetyIncidentById: {
      providesTags: (_result, _error, arg) => [{ type: 'SignOff', id: arg.id }, 'HealthAndSafetyIncident']
    },
    putApiHealthAndSafetyIncident: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'HealthAndSafetyIncident', id: arg.id }, 'HealthAndSafetyIncident']
    },
    deleteApiHealthAndSafetyIncidentById: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'HealthAndSafetyIncident', id: arg.id }, 'HealthAndSafetyIncident']
    },
    postApiHealthAndSafetyIncidentByIdSignOff: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'SignOff' }, 'HealthAndSafetyIncident']
    },
    //#endregion Consultations
    //#region BatchTemplates
    getApiEntityManagementEntityTemplate: {
      providesTags: ['BatchTemplates']
    },
    getApiEntityManagementEntityTemplateByTemplateId: {
      providesTags: ['BatchTemplates']
    },
    putApiEntityManagementEntityTemplate: {
      invalidatesTags: ['BatchTemplates']
    },
    getApiEntityManagementEntityTemplateHistory: {
      providesTags: ['BatchTemplates']
    },
    postApiEntityManagementEntityTemplateByFileName: {
      invalidatesTags: ['BatchTemplates']
    },
    //#endregion BatchTemplates
    //#region ReportingPeriod
    putApiCommonReportingPeriod: {
      invalidatesTags: ['ReportingPeriod']
      //invalidatesTags: (_result, _error, arg) => arg.reportingPeriodRequest.id ? [{ type: 'ReportingPeriod', id: arg.reportingPeriodRequest.id }, 'ReportingPeriod'] : ['ReportingPeriod']
    },
    getApiCommonReportingPeriod: {
      providesTags: (result, _error, _arg) => {
        var out: any[] = result?.map(rp => ({ type: 'ReportingPeriod', id: rp.id })) || [];
        out.push('ReportingPeriod');
        return out;
      }
    },
    //#endregion ReportingPeriod
    //#region Water
    putApiWaterMeter: {
      invalidatesTags: ['WaterMeter']
    },
    getApiWaterMeter: {
      providesTags: ['WaterMeter']
    },
    putApiWaterSource: {
      invalidatesTags: ['WaterSource']
    },
    getApiWaterSource: {
      providesTags: ['WaterSource']
    },
    getApiWaterSourceTypes: {
      providesTags: ['WaterSourceType']
    },
    putApiWaterMeterReading: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'WaterMeterReading', id: arg.waterMeterReadingRequest.waterMeterId }]
    },
    getApiWaterMeterByWaterMeterIdReadings: {
      providesTags: (_result, _error, arg) => [{ type: 'WaterMeterReading', id: arg.waterMeterId }]
    },
    putApiWaterMeterCalibration: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'WaterMeterCalibration', id: arg.waterMeterCalibrationRequest.waterMeterId }]
    },
    getApiWaterMeterByWaterMeterIdCalibrations: {
      providesTags: (_result, _error, arg) => [{ type: 'WaterMeterCalibration', id: arg.waterMeterId }]
    },
    getApiWaterSourceQualities: {
      providesTags: ['WaterQuality']
    },
    deleteApiWaterMeterReading: {
      invalidatesTags: (_result, _error, arg) => ['WaterMeterReading']
    },
    //#endregion Water
    //#region Notification
    getApiNotifications: {
      providesTags: (_result, _error) => [{ type: 'Notification' }]
    },
    putApiNotifications: {
      invalidatesTags:  ['Notification']
    },
    //#endregion Notification
    //#region Project
    getApiCommunitiesProjectProjects: {
      providesTags: (_result, _error) => [{ type: 'Project' }]
    },
    getApiCommunitiesProjectByProjectIdPayments: {
      providesTags: (_result, _error) => [{ type: 'ProjectPayment' }]
    },
    putApiCommunitiesProjectPayment: {
      invalidatesTags: () => ['ProjectPayment']
    },
    postApiCommunitiesProjectPayments: {
      invalidatesTags: () => ['ProjectPayment']
    },
    //#endregion Project
    //#region Waste

    putApiWasteDisposal: {
      invalidatesTags: ['WasteDisposal']
    },
    getApiWasteDisposalTable: {
      providesTags: ['WasteDisposal']
    },
    deleteApiWasteDisposalByWasteDisposalId: {
      invalidatesTags: ['WasteDisposal']
    },
    postApiWasteDisposalByWasteDisposalIdSignOff: {
      invalidatesTags: ['WasteDisposal', 'SignOff']
    },
    getApiWasteDisposal: {
      providesTags: (_result, _error, arg) => [{ type: 'SignOff', id: arg.wasteDisposalId }, 'WasteDisposal']
    },
    getApiWasteDisposalDashboard: {
      providesTags: ['WasteDisposal']
    },
    putApiWasteDisposalBatch: {
      invalidatesTags: ['WasteDisposal']
    },
    getApiWasteDisposalInputTemplates: {
      providesTags: ['WasteDisposal']
    },
    //#endregion Waste
    //#region RequiredDocuments
    getApiSignoffByEntityNameAllowedTransitions: {
      providesTags: (_result, _error) => [{ type: 'RequiredDocuments' }]
    },
    putApiSignoffRequiredDocuments: {
      invalidatesTags: ['RequiredDocuments']
    },
    deleteApiSignoffById: {
      invalidatesTags: ['RequiredDocuments'] 
    },
    //#endregion RequiredDocuments
    //#region SocialIncident
    putApiCommunitiesSocialIncident: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'SocialIncident', id: arg.id }, 'SocialIncident']
    },
    getApiCommunitiesSocialIncident: {
      providesTags: (_result, _error, arg) => [{ type: 'SocialIncident' }]
    },
    getApiCommunitiesSocialIncidentBySocialIncidentId: {
      providesTags: (_result, _error, arg) => [{ type: 'SignOff', id: arg.socialIncidentId }]
    },
    deleteApiCommunitiesSocialIncidentBySocialIncidentId: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'SocialIncident', id: arg.socialIncidentId }, 'SocialIncident']
    },
    postApiCommunitiesSocialIncidentBySocialIncidentIdSignOff: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'SignOff', id: arg.socialIncidentId }, 'SocialIncident']
    },
    //#endregion SocialIncident
    //#region IntensityMetric
   
    putApiCommonCustomMetric: {
      invalidatesTags: ['IntensityMetric']
    },
    getApiCommonCustomMetricNormalized: {
      providesTags: (_result, _error) => [{ type: 'IntensityMetric' }]
    },
    getApiCommonCustomMetric: {
      providesTags: (_result, _error) => [{ type: 'IntensityMetric' }]
    },
    getApiCommonCustomMetricByCustomMetricId: {
      providesTags: (_result, _error, arg) => [{ type: 'IntensityMetric', id: arg.customMetricId }]
    },
    postApiCommonCustomMetricByCustomMetricIdSignOff: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'IntensityMetric', id: arg.customMetricId }, 'IntensityMetric']
    },
    deleteApiCommonCustomMetric: {
      invalidatesTags: ['IntensityMetric']
    },
    putApiCommonCustomMetricBatch: {
      invalidatesTags: ['IntensityMetric']
    },
    //#endregion IntensityMetric
    //#region Dashboard
    getApiDashboards: {
      providesTags: ['Dashboard']
    },
    patchApiDashboards: {
      invalidatesTags: ['Dashboard']
    },
    //#endregion Dashboard
    //#region Company
    getApiCompany: {
      providesTags: ['Company']
    },
    putApiCompany: {
      invalidatesTags: ['Company']
    },
    deleteApiCompanyByCompanyGuid: {
      invalidatesTags: ['Company'] 
    },
    //#endregion Company
    //#region Target
    getApiTargetByTargetId: {
      providesTags: (_result, _error, arg) => [{ type: 'Target', targetId: arg.targetId }]
    },
    getApiTarget: {
      providesTags: (_result, _error, arg) => [{ type: 'Target', id: arg.module }] 
    },
    getApiTargetAll: {
      providesTags: (_result, _error, arg) => [{ type: 'Target', id: arg.module }]
    },
    putApiTargetEnergy: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'Target', id: arg.energyTargetRequest.module }, { type: 'Target', targetId: arg.energyTargetRequest.id }]
    },
    putApiTargetEnergyIntensity: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'Target', id: arg.energyIntensityTargetRequest.module }, { type: 'Target', targetId: arg.energyIntensityTargetRequest.id }]
    },
    putApiTargetNetzero: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'Target', id: arg.netZeroProjectTargetRequest.module }, { type: 'Target', targetId: arg.netZeroProjectTargetRequest.id }]
    },
    putApiTarget: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'Target', id: arg.targetRequest.module }, { type: 'Target', targetId: arg.targetRequest.id }]
    },
    deleteApiTargetByTargetId: {
      invalidatesTags: ['Target']
    },
    //#endregion Dashboard
    //#region SupplierReview
    getApiValueChainSupplierReview: {
      providesTags: ['SupplierReview'] 
    },
    putApiValueChainSupplierReview: {
      invalidatesTags: ['SupplierReview']
    },
    deleteApiValueChainSupplierReviewBySupplierReviewGuid: {
      invalidatesTags: ['SupplierReview']
    },
    //#endregion SupplierReview
    //#region CarbonTax
    getApiEnergyCarbonTax: {
      providesTags: ['CarbonTax']
    },
    putApiEnergyCarbonTax: {
      invalidatesTags: ['CarbonTax']
    },
    postApiEnergyCarbonTaxByIdSignOff: {
      invalidatesTags: ['CarbonTax']
    },
    deleteApiEnergyCarbonTaxByCarbonTaxInputId: {
      invalidatesTags: ['CarbonTax']
    },
    //#endregion CarbonTax
    //#region ConfiguredEmissionSource
    getApiEnergySources: {
      providesTags: ['ConfiguredEmissionSource']
    },
    getApiEnergySourcesSearch: {
      providesTags: ['ConfiguredEmissionSource']
    },
    putApiEnergySourcesDisplayName: {
      invalidatesTags: ['ConfiguredEmissionSource']
    },
    postApiEnergySourcesGas: {
      invalidatesTags: ['ConfiguredEmissionSource']
    },
    //#endregion ConfiguredEmissionSource
    //#region Operation
    getApiOperation: {
      providesTags: ['BiodiversityOperation']
    },
    // getApiOperationById: {
    //   providesTags: (_result, _error, arg) => [{ type: 'BiodiversityOperation', id: arg.id }]
    // },
    putApiOperation: {
      invalidatesTags: ['BiodiversityOperation']
    },
    deleteApiOperationById: {
      invalidatesTags: ['BiodiversityOperation']
    },
    //#endregion Operation
    //#region Biodiversity
    getApiBiodiversityPaginated: {
      providesTags: ['BiodiversityImpact']
    },
    getApiBiodiversityById: {
      providesTags: (_result, _error_, arg) => [{ type: 'SignOff', id: arg.id }]
    },
    putApiBiodiversity: {
      invalidatesTags: ['BiodiversityImpact']
    },
    deleteApiBiodiversityById: {
      invalidatesTags: ['BiodiversityImpact']
    },
    postApiBiodiversityByIdSignOff: {
      invalidatesTags: ['BiodiversityImpact', 'SignOff']
    },
    //#endregion Biodiversity
    //#region ImpactAssessment
    getApiCommunitiesImpactAssessment: {
      providesTags: ['ImpactAssessment']
    },
    getApiCommunitiesImpactAssessmentDashboard: {
      providesTags: (_result, _error, arg) => ['ImpactAssessment']
    },
    putApiCommunitiesImpactAssessment: {
      invalidatesTags: (_result, _error, arg) => arg.id ? ['ImpactAssessment', { type: 'ImpactAssessment', id: arg.id }] : ['ImpactAssessment']
    },
    getApiCommunitiesImpactAssessmentById: {
      providesTags: (_result, _error, arg) => [{ type: 'SignOff', id: arg.id }, { type: 'ImpactAssessment', id: arg.id }, 'ImpactAssessment']
    },
    deleteApiCommunitiesImpactAssessmentById: {
      invalidatesTags: (_result, _error, arg) => ['ImpactAssessment', { type: 'ImpactAssessment', id: arg.id }]
    },
    postApiCommunitiesImpactAssessmentByIdSignOff: {
      invalidatesTags: (_result, _error, arg) => ['ImpactAssessment', { type: 'SignOff' }]
    },
    //#endregion ImpactAssessment
    //#region Employees
    getApiEmployeeAudit: {
      providesTags: ['EmployeeAudit'] 
    },
    getApiEmployeeAuditPaginated: {
      providesTags: ['EmployeeAudit'] 
    },
    putApiEmployeeAudit: {
      invalidatesTags: ['EmployeeAudit']
    },
    deleteApiEmployeeAuditByEmployeeAuditId: {
      invalidatesTags: ['EmployeeAudit']
    },
    //#endregion Employees
  }
});
