import LoaderSuspense from 'components/navigation/loader-suspense';
import TargetModal from 'components/setup/targets/target-modal';
import TargetSetup from 'components/setup/targets/target-setup';
import CustomMetricRoutes from 'components/shared/custom-metrics/custom-metrics-routes';
import React from 'react';
import { Route } from 'react-router';

const WasteDisposalDataGrid = React.lazy(() => import('components/waste/waste-disposal/waste-disposal-data-grid'));
const WasteDisposalForm = React.lazy(() => import('components/waste/waste-disposal/form/waste-disposal-form'));
const WasteDisposalDashboardWrapper = React.lazy(() => import('components/waste/waste-disposal/waste-dashboard-wrapper'));
const AuditTrail = React.lazy(() => import('components/audit/audit-trail-grid'));
const BatchWasteCapture = React.lazy(() => import('components/waste/batch-capture/batch-waste-capture-form'));
const WasteDataManagementWrapper = React.lazy(() => import('components/waste/waste-disposal/waste-data-management-wrapper'));

const WasteRoutes = () => {
  return (<>
    {CustomMetricRoutes('Waste')}
    <Route
      path='waste'
      element={<LoaderSuspense outlet module='Waste'><WasteDisposalDashboardWrapper /></LoaderSuspense>} />
    <Route
      path='waste/:tab'
      element={<LoaderSuspense outlet module='Waste'><WasteDisposalDashboardWrapper /></LoaderSuspense>} />
    <Route
      path='waste' >
      <Route
        path='disposal/table'
        element={<LoaderSuspense outlet module='Waste'><WasteDisposalDataGrid /></LoaderSuspense>} >
        <Route
          path='modal'
          element={<LoaderSuspense modal module='Waste'><WasteDisposalForm /></LoaderSuspense>} />
        <Route
          path='modal/:wasteDisposalId'
          element={<LoaderSuspense modal module='Waste'><WasteDisposalForm /></LoaderSuspense>} />
        <Route
          path='modal/:recordId/audit-trail'
          element={<LoaderSuspense modal module='Waste'><AuditTrail module='Waste' type='WasteDisposal' /></LoaderSuspense>} />
        <Route
          path='modal/batch'
          element={<LoaderSuspense modal module='Energy'><BatchWasteCapture /></LoaderSuspense>} />
      </Route>
      <Route
        path='targets'
        element={<LoaderSuspense outlet module='Waste'><TargetSetup module='Waste' title='Waste Targets' /></LoaderSuspense>}
      >
        <Route
          path='modal'
          element={<LoaderSuspense modal module='Waste'><TargetModal module='Waste' title='Capture Waste Target' /></LoaderSuspense>} />
        <Route
          path='modal/:targetId'
          element={<LoaderSuspense modal module='Waste'><TargetModal module='Waste' title='Edit Waste Target' /></LoaderSuspense>} />
      </Route>
      <Route path='data-management'>
        <Route
          path='table'
          element={<LoaderSuspense outlet module='Waste'><WasteDataManagementWrapper /></LoaderSuspense>} >
        </Route>
      </Route>
    </Route>
  </>);
};

export default WasteRoutes;
