/* eslint-disable max-lines */
import React from 'react';
import { Navigate, Route } from 'react-router';
import LoaderSuspense from 'components/navigation/loader-suspense';
import CustomMetricBatchCaptureForm from './batch/custom-metric-batch-capture-form';

const CustomMetricsTabs = React.lazy(() => import('components/shared/custom-metrics/custom-metrics-tabs'));
const IntensityMetricForm = React.lazy(() => import('components/shared/custom-metrics/custom-metric-form'));
const AuditTrail = React.lazy(() => import('components/audit/audit-trail-grid'));

const CustomMetricRoutes = (module: ModuleEnum) => {
  return (<>
    <Route
      path='/:module/metrics'
      element={<Navigate to='./custom' />}
    />
    <Route
      path='/:module/metrics/:tab'
      element={<LoaderSuspense outlet module={module}><CustomMetricsTabs /></LoaderSuspense>} >
      <Route
        path='form/:module'
        element={<LoaderSuspense modal module={module}><IntensityMetricForm /></LoaderSuspense>} />
        
      <Route
        path='form/:module/:customMetricId'
        element={<LoaderSuspense modal module={module}><IntensityMetricForm /></LoaderSuspense>} />

      <Route
        path='modal/:recordId/audit-trail'
        element={<LoaderSuspense modal module={module}><AuditTrail module={module} type='CustomMetric' /></LoaderSuspense>} />

      <Route
        path='modal/batch'
        element={<LoaderSuspense modal module={module}><CustomMetricBatchCaptureForm /></LoaderSuspense>} />
    </Route>
  </>);
};

export default CustomMetricRoutes;
