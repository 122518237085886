import React, { Suspense } from 'react';
import AppDrawer from './components/navigation/app-drawer';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Stack, Box } from '@mui/material';
import BuildTheme from './App-theme';
import EconestRouter from 'EconestRouter';
import { api } from 'services/api/main';
import './layout.css';
import './print.css';

const AlertManager = React.lazy(() => import('./components/shared/alert'));

const App = () => {
  const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

  const { data: featureFlags } = api.useGetApiCommonSettingsFeatureFlagsQuery();
  const AppTheme = React.useMemo(() => BuildTheme(featureFlags?.colourPalette), [featureFlags?.colourPalette]);

  return (
    <ThemeProvider theme={AppTheme}>
      <BrowserRouter basename={baseUrl || undefined}>
        <CssBaseline />
        <Stack direction='row' sx={{ height: '100vh' }}>
          <AppDrawer />
          <Box display='flex' flexDirection='column'
            sx={{ width: '100%', backgroundColor: 'rgba(255,255,255)', overflow: 'hidden', overflowY: 'hidden' }} borderRadius={3} >
            <EconestRouter />
          </Box>
        </Stack>
      </BrowserRouter>
      <Suspense fallback={null}>
        <AlertManager />
      </Suspense>
    </ThemeProvider >
  );
};

export default App;
