import LoaderSuspense from 'components/navigation/loader-suspense';
import CustomMetricRoutes from 'components/shared/custom-metrics/custom-metrics-routes';
import React from 'react';
import { Route } from 'react-router';

const IncidentForm = React.lazy(() => import('components/health-and-safety/safety-incident/form/incident-form'));
const HealthAndSafetyDashboard = React.lazy(() => import('components/health-and-safety/hns-dashboard-wrapper'));
const IncidentDataGrid = React.lazy(() => import('components/health-and-safety/safety-incident/safety-incident-table/incident-data-grid'));
const TargetSetup = React.lazy(() => import('components/setup/targets/target-setup'));
const TargetModal = React.lazy(() => import('components/setup/targets/target-modal'));
const AuditTrail = React.lazy(() => import('components/audit/audit-trail-grid'));

const HealthAndSafetyRoutes = () => {
  return (<>
    {CustomMetricRoutes('HealthAndSafety')}
    <Route
      path='health-and-safety'
      element={<LoaderSuspense module='HealthAndSafety'><HealthAndSafetyDashboard /></LoaderSuspense>}
    />
    <Route
      path='health-and-safety/:tab'
      element={<LoaderSuspense module='HealthAndSafety'><HealthAndSafetyDashboard /></LoaderSuspense>}
    />
    <Route
      path='health-and-safety/targets'
      element={<LoaderSuspense outlet module='HealthAndSafety'><TargetSetup module='HealthAndSafety' title='Health & Safety Targets' /></LoaderSuspense>}
    >
      <Route
        path='modal'
        element={<LoaderSuspense modal module='HealthAndSafety'><TargetModal module='HealthAndSafety' title='Capture Health & Safety Target' /></LoaderSuspense>}
      />
      <Route
        path='modal/:targetId'
        element={<LoaderSuspense modal module='HealthAndSafety'><TargetModal module='HealthAndSafety' title='Edit Health & Safety Target' /></LoaderSuspense>}
      />
    </Route>
    <Route
      path='health'
      element={<LoaderSuspense module='HealthAndSafety'><HealthAndSafetyDashboard /></LoaderSuspense>}
    />
    <Route path='health/incidents'>
     
    </Route>
    <Route
      path='safety'
      element={<LoaderSuspense outlet module='HealthAndSafety'>
        <HealthAndSafetyDashboard />
      </LoaderSuspense>}
    />
    <Route path='safety/incidents'>
      <Route
        path='table'
        element={<LoaderSuspense outlet module='HealthAndSafety'><IncidentDataGrid /></LoaderSuspense>}
      >
      
        <Route
          path='modal'
          element={<LoaderSuspense modal module='HealthAndSafety'><IncidentForm /></LoaderSuspense>}
        />
        <Route
          path='modal/:incidentId'
          element={<LoaderSuspense modal module='HealthAndSafety'><IncidentForm /></LoaderSuspense>}
        >
        </Route>
        <Route
          path='modal/:recordId/audit-trail'
          element={<LoaderSuspense modal module='HealthAndSafety'><AuditTrail module='HealthAndSafety' type='SafetyIncident' /></LoaderSuspense>}
        />
      </Route>
    </Route>
  </>);
};

export default HealthAndSafetyRoutes;
