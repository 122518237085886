import LoaderSuspense from 'components/navigation/loader-suspense';
import CustomMetricRoutes from 'components/shared/custom-metrics/custom-metrics-routes';
import React from 'react';
import { Route } from 'react-router';

const CommunitiesDashboard = React.lazy(() => import('components/communities/communities-dashboard-wrapper'));
const FundingApplicationTable = React.lazy(() => import('components/communities/FundingApplication/funding-application-table/fundingApplication-data-grid'));
const FundingApplicationCapture = React.lazy(() => import('components/communities/FundingApplication/form/fundingApplication-form'));
const MonitoringAndEvaluationDataGrid = React.lazy(() => import('components/communities/ProjectEvaluation/monitoring-and-evaluation-table/monitoring-and-evaluation-data-grid'));
const ProjectPaymentModal = React.lazy(() => import('components/communities/ProjectEvaluation/payments/project-payment-modal'));
const KeyIndicatorModal = React.lazy(() => import('components/communities/ProjectEvaluation/key-indicator/key-indicator-modal'));
const ProjectPaymentsDataGrid = React.lazy(() => import('components/communities/ProjectEvaluation/project-payment-table/project-payments-data-grid'));
const ReportKeyIndicators = React.lazy(() => import('components/communities/ProjectEvaluation/report-key-indicators'));
const RequestProjectReport = React.lazy(() => import('components/communities/ProjectEvaluation/request-project-report'));
const ProjectReportList = React.lazy(() => import('components/communities/ProjectEvaluation/project-report-list'));
const ProjectReportAnswers = React.lazy(() => import('components/communities/ProjectEvaluation/project-report-answers'));
const EditProjectPaymentModal = React.lazy(() => import('components/communities/ProjectEvaluation/edit-project-payments/edit-project-payment-modal'));
const SocialIncidentTable = React.lazy(() => import('components/communities/social-incidents/social-incidents-table/social-incidents-data-grid'));
const SocialIncidentCapture = React.lazy(() => import('components/communities/social-incidents/form/social-incident-form'));
const CommunityImpactAssessmentsTable = React.lazy(() => import('components/communities/impact-assessments/community-impact-assessment-grid'));
const CommunityImpactAssessmentsModal = React.lazy(() => import('components/communities/impact-assessments/community-impact-assessment-modal'));
const AuditTrail = React.lazy(() => import('components/audit/audit-trail-grid'));
const BusinessPartnerForm = React.lazy(() => import('components/communities/forms/partner-form'));

const CommunitiesRoutes = () => {
  return (<>
    {CustomMetricRoutes('Communities')}
    <Route
      path='communities'
      element={<LoaderSuspense module='Communities'><CommunitiesDashboard /></LoaderSuspense>} />
    <Route
      path='communities/:tab'
      element={<LoaderSuspense module='Communities'><CommunitiesDashboard /></LoaderSuspense>} />
    <Route path='communities/impact-assessment' element={<LoaderSuspense module='Communities'><CommunitiesDashboard /></LoaderSuspense>} />
    <Route path='communities/impact-assessment'>
      <Route
        path='table'
        element={<LoaderSuspense outlet module='Communities'><CommunityImpactAssessmentsTable /></LoaderSuspense>}
      >
        <Route
          path='modal'
          element={<LoaderSuspense modal module='Communities'><CommunityImpactAssessmentsModal /></LoaderSuspense>}
        />
        <Route
          path='modal/:impactAssessmentId'
          element={<LoaderSuspense modal module='Communities'><CommunityImpactAssessmentsModal /></LoaderSuspense>}
        />
        <Route
          path='modal/:recordId/audit-trail'
          element={<LoaderSuspense modal module='Communities'><AuditTrail module='Communities' type='ImpactAssessment' /></LoaderSuspense>}
        />
      </Route>
    </Route>
    <Route path='communities/csi'>
      <Route
        path='table'
        element={<LoaderSuspense outlet module='Communities'><FundingApplicationTable /></LoaderSuspense>}
      >
        <Route
          path='modal'
          element={<LoaderSuspense modal module='Communities'><FundingApplicationCapture /></LoaderSuspense>}
        />
        <Route
          path='modal/:fundingApplicationId'
          element={<LoaderSuspense modal module='Communities'><FundingApplicationCapture /></LoaderSuspense>}
        />
        <Route
          path='modal/:recordId/audit-trail'
          element={<LoaderSuspense modal module='Communities'><AuditTrail module='Communities' type='FundingApplication' /></LoaderSuspense>}
        />
      </Route>
    </Route>
    <Route path='communities/projects'>
      <Route
        path='table'
        element={<LoaderSuspense outlet module='Communities'><MonitoringAndEvaluationDataGrid /></LoaderSuspense>}
      >
        <Route
          path='modal'
          element={<LoaderSuspense modal module='Communities'><FundingApplicationCapture /></LoaderSuspense>}
        />
        <Route
          path='modal/:projectId'
          element={<LoaderSuspense modal module='Communities'><FundingApplicationCapture /></LoaderSuspense>}
        />
        <Route
          path='payments/:projectId'
          element={<LoaderSuspense modal module='Communities'><ProjectPaymentModal /></LoaderSuspense>}
        />
        <Route
          path='edit-payments/:projectPaymentId'
          element={<LoaderSuspense modal module='Communities'><EditProjectPaymentModal /></LoaderSuspense>}
        />
        <Route
          path='indicators/:projectId'
          element={<LoaderSuspense modal module='Communities'><KeyIndicatorModal /></LoaderSuspense>}
        />
        <Route
          path='payments-list/:projectId'
          element={<LoaderSuspense modal module='Communities'><ProjectPaymentsDataGrid /></LoaderSuspense>}
        />
        <Route
          path='reports/:projectId'
          element={<LoaderSuspense modal module='Communities'><RequestProjectReport /></LoaderSuspense>}
        />
        <Route
          path='report-list/:projectId'
          element={<LoaderSuspense outlet modal module='Communities'><ProjectReportList /></LoaderSuspense>}
        >
          <Route
            path='report-answers/:projectReportId'
            element={<LoaderSuspense modal module='Communities'><ProjectReportAnswers /></LoaderSuspense>}
          />
        </Route>
      </Route>
      <Route
        path='report/:projectReportId'
        element={<LoaderSuspense allowAnonymous><ReportKeyIndicators /></LoaderSuspense>}
      />
    </Route>
    <Route path='communities/social-incidents'>
      <Route
        path='table'
        element={<LoaderSuspense outlet module='Communities'><SocialIncidentTable /></LoaderSuspense>}
      >
        <Route
          path='form'
          element={<LoaderSuspense outlet modal module='Communities'><SocialIncidentCapture /></LoaderSuspense>}
        >
          <Route
            path='businessPartner'
            element={<LoaderSuspense modal module='Communities'><BusinessPartnerForm /></LoaderSuspense>} />
        </Route>
        <Route
          path='form/:socialIncidentId'
          element={<LoaderSuspense outlet modal module='Communities'><SocialIncidentCapture /></LoaderSuspense>} >
          <Route
            path='businessPartner'
            element={<LoaderSuspense modal module='Communities'><BusinessPartnerForm /></LoaderSuspense>} />
        </Route>
        <Route
          path='modal/:recordId/audit-trail'
          element={<LoaderSuspense modal module='Communities'><AuditTrail module='Communities' type='SocialIncident' /></LoaderSuspense>}
        />
      </Route>
    </Route>
  </>);
};

export default CommunitiesRoutes;
