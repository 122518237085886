import { DateDto } from '../../services/api/main';
import { DateTime, Duration } from 'luxon';
import * as Yup from 'yup';
import { transformToDateDto } from './date';

const startOfMonth = DateTime.now().minus(Duration.fromObject({ months: 1 })).startOf('month');
const endOfMonth = startOfMonth.endOf('month');
const defaultStartDate: DateDto = { day: startOfMonth.day, month: startOfMonth.month, year: startOfMonth.year };
const defaultEndDate: DateDto = { day: endOfMonth.day, month: endOfMonth.month, year: endOfMonth.year };
const blankEndDate: DateDto = { day: 31, month: 12, year: 2999 };

export const validateDateRange = () => {
  return Yup.object({
    startDate: Yup.mixed<DateDto>().defined().transform(transformToDateDto).required('Start date is required').default(defaultStartDate),
    endDate: Yup.mixed<DateDto>().defined().transform(transformToDateDto).required('End date is required').default(defaultEndDate)
  })
    .test('dateTest', 'End date should be after start date', (dateRange) => 
      DateTime.fromObject(dateRange.endDate).toJSDate() > DateTime.fromObject(dateRange.startDate).toJSDate())
    .defined();
};

export const validateDateRangePartial = () => {
  return Yup.object({
    startDate: Yup.mixed<DateDto>().defined().transform(transformToDateDto).required('Start date is required').default(defaultStartDate),  
    endDate: Yup.mixed<DateDto>().transform(transformToDateDto).optional().default(blankEndDate)
  }).defined();
};

export const validate2DecValue = () => {
  return Yup.number().test(
    'maxDigitsAfterDecimal',
    'Monetary values should have at least 2 decimals',
    (number) => number === undefined || number === null || /^\d+(\.\d{1,2})?$/.test(String(number!))
  );
};
