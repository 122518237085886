import React from 'react';
import { FormControl, FormControlProps, FormHelperText, InputLabel, MenuItem, Select, Stack, SxProps, Theme, Tooltip, Box, CircularProgress, Fade } from '@mui/material';
import { useField } from 'formik';
import { CaretDown } from 'icons/icon-components';
import { sortAlphabeticallyGeneric } from 'common';

interface GenericDropdownProps<T> extends Omit<FormControlProps, 'onChange'> {
  selectElementId: string,
  selectElementLabel: string,
  selectedItemId?: string | number,
  getKey: (item: T) => string | number;
  getDisplay: (item: T) => string;
  getSelectedDisplay?: (item: T) => React.ReactNode;
  isLoading?: boolean;
  data?: T[];
  name?: string;
  onChange?: (item?: T) => void;
  tooltip?: string;
  tooltipPlacement?: 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top' | undefined;
  disabled?: boolean
  isOptional?: boolean;
  isMultiLine?: boolean;
  stackSx?: SxProps<Theme>;
}

const GenericDropdown = <T extends unknown>(props: GenericDropdownProps<T>) => {
  const {
    selectElementId,
    selectElementLabel,
    selectedItemId,
    getKey,
    getDisplay,
    getSelectedDisplay,
    isLoading = false,
    data,
    name,
    onChange,
    tooltip,
    tooltipPlacement,
    disabled,
    stackSx,
    isOptional = false,
    isMultiLine = false,
    ...formControlProps } = props;

  const [dropdownField, metaDropdown] = useField(name ?? '');
  const { value, ...dropdownFieldRest } = dropdownField;

  const handleSelectedDisplay = (id: string | number) => {
    const item = data?.find(i => getKey(i) === id);
    if (!item) return '';

    return !!getSelectedDisplay ? getSelectedDisplay(item!) : getDisplay(item!);
  };

  const handleMultilineDisplay = (selected: string | number) => {
    return isMultiLine ?
      <Box
        sx={{
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 4
        }}>
        {handleSelectedDisplay(selected)}
      </Box> :
      handleSelectedDisplay(selected);
  };

  return (
    <Stack sx={{ flex: 1, ...(stackSx ?? {}) }}>
      {
        isLoading || !data
          ? <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #cbcbcb', borderRadius: '4px', padding: '5px' }}>
            <CircularProgress sx={{ width: '100px' }} />
          </Box>
          : <Fade in>
            <FormControl sx={{ minWidth: 180 }} {...formControlProps} disabled={disabled ?? false}>
              <InputLabel id={`${selectElementId}-label`}>{`${isOptional ? '(Optional) ' : ''}` + selectElementLabel}</InputLabel>
              <Tooltip placement={tooltipPlacement ?? 'bottom'} title={tooltip ?? ''}>
                <Select
                  labelId={`${selectElementId}-label`}
                  id={name}
                  renderValue={handleMultilineDisplay}
                  label={`${isOptional ? '(Optional) ' : ''}` + selectElementLabel}
                  autoWidth
                  IconComponent={CaretDown}
                  {...(Boolean(name) ? dropdownFieldRest : null)}
                  value={selectedItemId ?? value}
                  onChange={(e) => {
                    dropdownField.onChange(e);
                    if (onChange)
                      onChange(data?.find(i => getKey(i) === e.target.value));
                  }}>
                  {
                    [...data].sort((a, b) => sortAlphabeticallyGeneric(a, b, getDisplay))
                      .map(s => <MenuItem value={getKey(s)} key={getKey(s)}>{getDisplay(s)}</MenuItem>)
                  }
                </Select>
              </Tooltip>
            </FormControl>
          </Fade> 
          
      }
      
      {(Boolean(name) && Boolean(metaDropdown) && Boolean(metaDropdown.error) && Boolean(metaDropdown.touched)) ?
        <FormHelperText sx={{ ml: 1, mt: 0, mb: 1 }} error>{metaDropdown.error}</FormHelperText> :
        null}
    </Stack>
  );
};

export default GenericDropdown;