import React from 'react';
import Dialog from '@mui/material/Dialog';
import { Button, DialogActions, DialogContent, Grid, Typography, Stack, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Property } from 'csstype';
import ActionSidebar from './action-sidebar';

interface ModalProps {
  title: string;
  isOpen: boolean;
  maxWidth?: string;
  overflow?: Property.Overflow;
  onClose: () => void;
  sideBarChildren?: React.ReactNode;
  closeOnBackdropClick?: boolean;
  secondary?: boolean;
}

const primaryContainer = document.getElementById('modals-primary');
const secondaryContainer = document.getElementById('modals-secondary');

const Modal = React.memo((props: React.PropsWithChildren<ModalProps>) => {
  const appTheme = useTheme();
  const { secondary, isOpen, title, children, onClose, maxWidth = '600px', overflow = 'visible', sideBarChildren, closeOnBackdropClick = true } = props;

  return (
    <Dialog disableEnforceFocus={!secondary} hideBackdrop={!!secondary} container={secondary ? secondaryContainer : primaryContainer} PaperProps={{ sx: { maxWidth, overflow, border: 'none', borderRadius: '10px' } }} open={isOpen} onClose={(_, reason) => (reason === 'backdropClick' && closeOnBackdropClick) ? onClose() : null} fullWidth>
      <DialogActions sx={{ background: appTheme.palette.secondary.main, border: 'none', borderTopLeftRadius: 10, borderTopRightRadius: 10 }} >
        <Grid container spacing={1} >
          <Grid item xs={8} >
            <Typography variant="h5" gutterBottom component="div" textAlign='left' color='primary.main' sx={{ mt: 1, ml: 1 }}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={4} textAlign='right'>
            <Button onClick={onClose} startIcon={<Close sx={{ color: 'white' }} />} sx={{ mt: 1 }}></Button>
          </Grid>
        </Grid>
      </DialogActions>
      <DialogContent sx={{ overflow: 'scroll-y' }}>
        {children}
        {
          sideBarChildren &&
          <ActionSidebar
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <Stack
              alignItems='center'
              justifyContent='center'
            >
              {sideBarChildren}
            </Stack>
          </ActionSidebar>
        }
      </DialogContent>
    </Dialog>
  );
});

export default Modal;